.filter-item {
    padding: 8px;
    display: flex;
    background-color: var(--primary-bg);
    color: #000000;
    border: 1px solid var(--grey);
    border-radius: 8px;
    margin: 10px 10px 10px 0px;
    font-size: 14px;
  }
  
  .filter-name {
    margin-left: 5px;
  }