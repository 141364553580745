#root{
    --primary-box-shadow: rgb(28 28 28 /8%) 0px 2px 8px;
    --border-white: rgb(232, 232, 232);
    --primary-bg : rgb(255, 255, 255);
    --grey : #828282;
    --red : #ff7e8b;
    --grey-seperator: rgb(207, 207, 207);
    --inactive-text: #696969;
    --inactive-color: #f8f8f8;
    --filter-text: #9c9c9c;
    --heading-text: #1c1c1c;
    --collection-shadow: rgb(0 0 0 / 8%) 0px 3px 12px;
    --tag-red: #d92662;
    --tag-blue: #000000;
    --tag-green: #267e3e;
}