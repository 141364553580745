@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url(./styles/commonClasses.css);

@import url(./styles/variables.css);

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antisaliased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

