.header {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  position: fixed;
  top: 0;
  z-index: 1;
  background-color: white;
}

/* .sticky {
  position: fixed;
  top: 0;
  width: 100%;
} */

.header-logo {
  margin: 0 auto;
  padding: 8px;
  height: 50px;
  /* //max-width: 500px; */
  /* margin-right: 24px; */
}

.header-right {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 72px;
}

.header-location-search-container {
  height: 54px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: var(--primary-box-shadow);
  border: 1px solid var(--border-white);
  width: 70%;
  background-color: var(--primary-bg);
}

.location-wrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0px 10px;
}

.location-icon-name {
  display: flex;
  color: var(--grey);
}

.location-icon {
  color: var(--red);
  font-size: 20px;
  margin-right: 8px;
}

.location-search-seperator {
  height: 20px;
  border: 0.5px solid var(--grey-seperator);
}

.header-searchbar {
  display: flex;
  flex: 2;
}

.search-icon {
  font-size: 20px;
  color: var(--grey);
  margin: 0px 12px;
}

.search-input {
  border: none;
  outline: none;
  width: 60%;
  font-size: 14px;
}

.profile-wrapper {
  display: flex;
  justify-content: space-between;
  width: 15%;
  align-items: center;
}

.header-profile-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.header-username {
  font-size: 16px;
  font-weight: 600;
}

.profile-options-icon {
  font-size: 20px;
}
