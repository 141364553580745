.filters {
  /* padding: 16px 0px; */
  width: 100%;
margin-top: 40px;  
  display: flex;
  background-color: black;
  position: fixed;
  z-index: 100;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

::-webkit-scrollbar {
  display: none;
}
.filter-item {
  padding: 10px;
  display: flex;
  background-color: black;
  color: #ffffff;
  text-decoration: none;
  border: 1px solid black;
  border-radius: 8px;
  margin: 10px 10px 10px 0px;
  font-size: 14px;
}



.selected-filter-item {
  padding: 8px;
  display: flex;
  background-color: #ffffff;
  color: rgb(255, 255, 255);
  /* border: 1px solid var(--grey); */
  border-radius: 8px;
  margin: 10px 10px 10px 0px;
  font-size: 14px;
}


.active,
.active-scroll-spy {
  color: black;
  background-color: rgb(255, 255, 255);

}

.active:hover,
.active-scroll-spy :hover{
  color: rgb(255, 255, 255);

}

.filters>div{
  display: flex;
}