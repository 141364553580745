.tab-options {
  box-shadow: var(--border-white) 0px -0.5px 0px inset;
  margin-top: 50px;
  /* position: fixed; */
  /* top: 0; */
}

.options-wrapper {
  display: flex;
}

.tab-item {
  margin-right: 24px;
  padding: 12px 12px 20px 12px;
}

.tab-name {
  font-size: 20px;
  font-weight: 600;
  margin-left: 10px;
  color: var(--inactive-text);
}

.tab-image-container {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: var(--inactive-color);
}

.tab-image {
  height: 30px;
  width: 30px;
}

.active-tab {
  border-bottom: 2px solid var(--red);
}

.active-tab .tab-name {
  color: var(--red);
}
