.explore-card {
  margin: 12px 0px 24px 0px;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid transparent;
}
.explore-card-first {
  margin-top: 12px;
}
/* 
model-viewer{

    display: none;

} */

.arbtn{
display: none;
}


.ar-btnn{
  padding: 0;
border: none;
background: none;
}
.explore-card-cover {
  height: 248px;
  width: 328px;
  border-radius: 15px;
  position: relative;
}

.explore-card-image {
  height: 100%;
  width: 88%;
  object-fit: cover;
  border-radius: 15px;
}

.imgz
{
  height: 60px;
  width: 60px;
}

.delivery-time {
  position: absolute;
  right: 39px;
  bottom: 0px;
  /* opacity: 0.8; */
  /* background-color: var(--primary-bg); */

  /* backdrop-filter: blur(4px); */
  /* font-weight: 600; */
  font-size: 24px;
  padding: 0px 0px 0px;
  /* border-radius: 4px; */
}

.pro-off {
  position: absolute;
  left: 0px;
  bottom: 40px;
  background-color: var(--tag-red);
  color: var(--primary-bg);
  font-size: 14px;
  padding: 0px 6px;
  border-radius: 0px 2px 2px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  font-weight: 500;
}

.discount {
  position: absolute;
  background-color: var(--tag-blue);
  color: var(--primary-bg);
  font-weight: 500;
  font-size: 14px;
  padding: 0px 6px;
  border-radius: 0px 2px 2px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  left: 0px;
  bottom: 14px;
}
.gold-off {
  position: absolute;
  background-color: var(--tag-red);
  color: var(--primary-bg);
  font-weight: 500;
  font-size: 14px;
  padding: 0px 6px;
  border-radius: 0px 2px 2px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  left: 0px;
  bottom: 14px;
}
.res-row {
  max-width: 250px;
  display: flex;
  justify-content: space-between;
  padding: 8px 0px 0px;
}

.res-name {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 600;
  color: var(--heading-text);
}

.res-rating {
  padding: 0px 8px;
  font-size: 13px;
  font-weight: 600;
  color: var(--primary-bg);
  background-color: var(--tag-green);
  border-radius: 6px;
  height: 24px;
}

.res-rating i {
  font-size: 10px;
  margin-left: 2px;
}

.res-cuisine {
  max-width: 56%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.res-cuisine-tag {
  margin-right: 4px;
  color: var(--inactive-text);
}

.res-price {
  font-size: 14px;
  color: var(--inactive-text);
}

.explore-bottom {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.res-bottom-text {
  font-size: 12px;
  color: var(--inactive-text);
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
}

.card-separator {
  height: 1px;
  margin: 10px 0px 5px;
  background-color: var(--border-white);
}

.explore-card:hover {
  background-color: var(--primary-bg);
  border: 1px solid var(--border-white);
}

@media (min-width: 1391px) {

  /* THINK IT GOES HERE
   */
  /* .delivery-time {
    display: none;
  } */
  .res-name {
    font-size: 17px;
  }
  .res-row-textflow {
    /* overflow-y: scroll; */
    height: 170px;
    width: auto;

    /* font-size: 10px; */
  }
}

@media (max-width: 1390px) and (min-width: 1050px) {
  .explore-card {
    width: 250px;
    height: 385px;
  }

  .res-name {
    font-size: 17px;
  }

  .res-row {
    width: 230px;
  }

  .res-row-textflow {
    overflow-y: scroll;
    height: 162px;
    width: auto;
    
    font-size: 10px;

  }

  .explore-card-cover {
    width: 255px;
    height: 150px;
  }
  .explore-card-image {
    width: 88%;
    height: 100%;
  }
  .delivery-time {
    right: 39px;
    bottom: 0px;
    font-size: 18px;
    padding: 0px 12px 7px;
  }
}

/* 984 to 1050 */

@media (max-width: 1049px) and (min-width: 768px) {
  .explore-card {
    width: 220px;
    height: 330px;
  }

  .res-name {
    font-size: 17px;
  }

  .res-row-textflow {
    overflow-y: scroll;
    height: 184px;
    font-size: 10px;
    
width: auto;

  }

  .explore-card-cover {
    width: 226px;
    height: 118px;
  }

  

  .explore-card-image {
    width: 88%;
    height: 100%;
  }
  .imgz
  {
    height: 30px;
    width: 30px;
  }

  .delivery-time {
    right: 15px;
    bottom: 0px;
    font-size: 14px;
    padding: 4px 10px 6px;
  }
}

@media (max-width: 767px) and (min-width: 514px) {
  .explore-card {
    width: 170px;
    height: 430px;
  }

  .res-name {
    font-size: 15px;
  }

  .res-row-textflow {
    overflow-y: scroll;
    height: 206px;
    font-size: 10px;
    


  }

  .explore-card-cover {
    width: 180px;
    height: 150px;
  }

  .explore-card-image {
    width: 88%;
    height: 100%;
  }
  .imgz
  {
    height: 30px;
    width: 30px;
  }

  .delivery-time {
    right: 15px;
    bottom: 0px;
    font-size: 14px;
    padding: 4px 10px 6px;
  }
}

@media (max-width: 513px) and (min-width: 464px) {
  .explore-card {
    width: 160px;
    height: 440px;
  }

  .res-name {
    font-size: 14px;
  }

  .res-row-textflow {
    overflow-y: scroll;
    height: 228px;
    font-size: 10px;

  }

  .explore-card-cover {
    width: 170px;
    height: 140px;
  }

  .explore-card-image {
    width: 83%;
    height: 100%;
  }
  .imgz
  {
    height: 30px;
    width: 30px;
  }

  .delivery-time {
    right: 15px;
    bottom: 0px;
    font-size: 14px;
    padding: 4px 10px 6px;
  }
}

@media (max-width: 463px) and (min-width: 404px) {
  .explore-card {
    width: 180px;
    height: 395px;
  }

  .res-name {
    font-size: 15px;
  }

  .imgz
{
  height: 30px;
  width: 30px;
}

  /* IPHONE XR */
  .res-row-textflow {
    overflow-y: scroll;
    height: 184px;
    font-size: 12px;

  }

  .explore-card-cover {
    width: 190px;
    height: 130px;
  }

  .explore-card-image {
    width: 88%;
    height: 100%;
  }
  .delivery-time {
    right: 15px;
    bottom: 0px;
    font-size: 14px;
    padding: 4px 10px 6px;
  }
}

@media (max-width: 403px) and (min-width: 345px) {
  .explore-card {
    width: 160px;
    height: 310px;
  }

  .res-name {
    font-size: 13px;
  }

  .res-row {
    font-size: 12px;
  }

  /* iPhone 12 Pro */
  .res-row-textflow {
    overflow-y: scroll;
    height: 136px;
    font-size: 10px;

  }


  
  .explore-card-cover {
    width: 160px;
    height: 130px;
  }

  .explore-card-image {
    width: 88%;
    height: 100%;
  }
  .ar-image{
    width: 8px;
    height: 8px;
  }

  .imgz
  {
    height: 30px;
    width: 30px;
  }

  .delivery-time {
    right: 15px;
    bottom: 0px;
    font-size: 14px;
    padding: 4px 10px 6px;
  }
}

@media (max-width: 344px) and (min-width: 294px) {
  .explore-card {
    width: 144px;
    height: 340px;
  }
  .res-row {
    font-size: 12px;
  }

  .res-row-textflow {
    overflow-y: scroll;
    height: 152px;
    font-size: 10px;

  }

  .res-name {
    font-size: 11px;
  }

  .explore-card-cover {
    width: 130px;
    height: 130px;
  }

  .explore-card-image {
    width: 90%;
    height: 100%;
  }
  .imgz
  {
    height: 30px;
    width: 30px;
  }

  .delivery-time {
    right: 15px;
    bottom: 0px;
    font-size: 14px;
    padding: 4px 10px 6px;
  }
}

@media (max-width: 293px) and (min-width: 244px) {
  .explore-card {
    width: 100px;
    height: 400px;
  }
  .res-row {
    font-size: 12px;
  }
  .res-name {
    font-size: 13px;
  }

  .res-row-textflow {
    overflow-y: scroll;
    height: 184px;
    font-size: 10px;

  }

  .explore-card-cover {
    width: 110px;
    height: 130px;
  }

  .explore-card-image {
    width: 90%;
    height: 100%;
  }
  .imgz
  {
    height: 30px;
    width: 30px;
  }

  .delivery-time {
    right: 15px;
    bottom: 0px;
    font-size: 14px;
    padding: 4px 10px 6px;
  }
}

@media (max-width: 243px) {
  .explore-card {
    width: 75px;
    height: 400px;
  }
  .res-row {
    font-size: 12px;
  }

  .res-row-textflow {
    overflow-y: scroll;
    height: 220px;
    font-size: 10px;

  }
  .res-name {
    font-size: 13px;
  }

  .explore-card-cover {
    width: 90px;
    height: 100px;
  }

  .explore-card-image {
    width: 90%;
    height: 100%;
  }
  .imgz
  {
    height: 30px;
    width: 30px;
  }

  .delivery-time {
    right: 15px;
    bottom: 0px;
    font-size: 14px;
    padding: 4px 10px 6px;
  }
}