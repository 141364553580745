.explore-section {
  margin-top: 16px;
}
.section-item {
  /* display: flex;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  justify-items: center;
}

.explore-grid {
  /* display: flex; */
  /* flex-wrap: wrap; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  /* justify-content: space-between; */
}
@media (max-width: 1390px) and (min-width: 1050px) {
  .explore-grid {
    justify-content: space-around;
  }
  .explore-section {
    width: 80%;
  }
}

@media (max-width: 1049px) and (min-width: 1029px) {
  .explore-grid {
    justify-content: space-around;
  }
  .explore-section {
    width: 92%;
  }
}
/* width crash bw 780's and 1050's // 1029-1049*/

/* @media (min-width: 968px) and (max-width: 1028px) {
  .explore-grid {
    justify-content: space-around;
  }
  .explore-section {
    width: 97%;
  }
} */

@media (max-width: 1028px) and (min-width: 1014px) {
  .explore-grid {
    justify-content: space-around;
  }
  .explore-section {
    width: 94%;
  }
}

@media (max-width: 1013px) and (min-width: 768px) {
  .explore-grid {
    justify-content: space-around;
  }
  .explore-section {
    width: 95%;
  }

}

@media (max-width: 767px) and (min-width: 621px) {
  .explore-grid {
    justify-content: space-around;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    margin-top: 0px;
  }
  .explore-section {
    width: 70%;
  }
  .section-item {
    /* display: flex;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: 2fr 2fr !important;
    grid-template-rows: auto;
  justify-items: center;

  }
}

@media (max-width: 620px) and (min-width: 500px) {
  .explore-grid {
    justify-content: space-around;
    margin-top: 45px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .explore-section {
    width: 90%;
  }
  .section-item {
    /* display: flex;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: 2fr 2fr !important;
    grid-template-rows: auto;
  justify-items: center;

  }
}

@media (max-width: 499px) and (min-width: 342px) {
  .explore-grid {
    justify-content: space-around;
    margin-top: 45px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .section-item {
    /* display: flex;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: 2fr 2fr !important;
    grid-template-rows: auto;
  justify-items: center;

  }
}

@media (max-width: 341px) and (min-width: 244px) {
  .explore-grid {
    justify-content: space-around;
    margin-top: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .section-item {
    /* display: flex;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: 2fr 2fr !important;
    grid-template-rows: auto;
  justify-items: center;

  }
}

@media (max-width: 243px) {
  .explore-grid {
    justify-content: space-around;
    margin-top: 150px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .explore-section {
    width: 85%;
  }
  .section-item {
    /* display: flex;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: 2fr 2fr !important;
    grid-template-rows: auto;
  justify-items: center;

  }
}


