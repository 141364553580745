.max-width{
    max-width: 1100px;
    margin: 0px auto;
}

.max-width-header{
    max-width: 100%;
    margin: 0px auto;
}

.absolute-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.cur-po{
    cursor: pointer;

}

.collection-title{
    font-size: 30px;
    color: var(--heading-text);
    margin-bottom: 32px;
    font-weight: 600;
}

.slick-next:before,
.slick-prev:before{
    color: var(--grey) ;
}